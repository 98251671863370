.double-two-section{
    padding: 4.4rem 0rem;
    .double-two-section--background-gray{
        background-color: var(--gray-color);
        padding: 5.4rem 3.4rem;
        border-top: #505050 solid 0.9rem;
    }  
    .double-two-section--boxes{
        display: grid;
        grid-template-columns: repeat(auto-fill, 1fr);
        gap: 3.4rem;
        margin: 3.8rem 0rem;
        p{            
            background-color: var(--primary-color);
            text-align: center;            
            padding: 1.1rem;
            border-radius: 1.2rem;
            min-width: 125px;           
            img{
                margin-top: -4.1rem;
             }
            a{
                color: var(--white-color);
                text-decoration: none;
            }
        }
        p:hover{
            background-color: var(--third-color);
        }
    }
    .double-two-section--btn{
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        line-height: 1.428571429;
        margin-bottom: 1.2rem;
        background: var(--gray-color);
        color: var(--secondary-color);
        border: 1px solid var(--secondary-color);
        border-radius: 10px;
        padding: 1rem 2rem;
        user-select: none;
        text-decoration: none;
    }
    .double-two-section--btn:hover{
        background: var(--secondary-color);
        color: var(--white-color);
    }  
    .double-two-section--bottom-line{
        border-bottom: 1px solid var(--black-color);
    }  
    .double-two-section--content-tag{
        a{
            background-color: var(--secondary-color);
            color: var(--white-color);
            text-decoration: none;
            padding: 0.5rem;
            border-radius: 5px;
        }
    }
}
.ponte-en-contacto{
    div:first-child{
        margin-top: -70px;
        margin-bottom: -30px;
        background-color: var(--white-color)!important;
        border-radius: 2.0rem;
        box-shadow: 1.7rem 1.7rem 0rem 0rem gray;
        h2{
            background-color: var(--third-color);
            padding: 2.8rem;
            border-radius: 2.0rem 2.0rem 0rem 0rem;
            color: var(--white-color);
        }
        p{
            margin: 1.8rem 2.8rem
        }
    }
}

// Small devices 
@media (min-width: 576px) {

}

// Medium devices 
@media (min-width: 768px) {
    .double-two-section{
        .double-two-section--boxes{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

// Large devices
@media (min-width: 992px) {

}

// Extra large devices
@media (min-width: 1200px) {

}

// Extra Extra large devices 
@media (min-width: 1400px) {
    .double-two-section{
        .double-two-section--boxes{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1.2rem;
        }
    }
}
